import { UserIcon } from '@heroicons/react/outline';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { CgClose, CgMenuRightAlt } from 'react-icons/cg';

import logoImage from '@/public/images/logo.svg';
import i18n from '@/src/i18n';
import { addParameter } from '@/src/utils/addParameter';
import { getAmplitudeDeviceId } from '@/src/utils/amplitude';
import { dashboardUrl } from '@/src/utils/processenv';

import { ChooseLang } from '../ChooseLang';
import { DropDownMenu } from '../DropDownMenu';

interface IProps {
  displayShadow: boolean;
}

export const Header = ({ displayShadow }: IProps) => {
  const [signupUrl, setSignupUrl] = useState(`${dashboardUrl}/signup`);
  const [loginUrl, setLoginUrl] = useState(`${dashboardUrl}/signin`);
  // const { t } = useTranslation();
  const path = usePathname();

  function updateUrls(amplitudeId: string) {
    setSignupUrl(
      addParameter(signupUrl, 'amplitudeDeviceId', amplitudeId).toString()
    );
    setLoginUrl(
      addParameter(loginUrl, 'amplitudeDeviceId', amplitudeId).toString()
    );
  }

  useEffect(() => {
    const aId = getAmplitudeDeviceId();
    if (aId) {
      updateUrls(aId);
    } else {
      setTimeout(() => {
        updateUrls(getAmplitudeDeviceId());
      }, 500);
    }
  }, []);

  const Links = [
    {
      name: 'Features',
      link: '/features/',
      subMenu: [
        {
          name: 'All Features',
          link: '/features/',
        },
        {
          name: 'AbleSpace AI',
          link: '/features/ablespace-ai/',
        },
        {
          name: 'IEP Audit',
          link: '/features/iep-audit/',
        },
        {
          name: 'Data Types',
          link: '/features/data-types/',
        },
        {
          name: 'Service Time',
          link: '/features/service-time/',
        },
        {
          name: 'Accommodations',
          link: '/features/accomodations/',
        },
        {
          name: 'Custom Goals',
          link: '/features/custom-goals/',
        },
        {
          name: 'Rotating Schedule',
          link: '/features/rotating-schedule/',
        },
      ],
    },
    {
      name: 'Support',
      link: '/support/',
      subMenu: [
        { name: 'FAQ', link: '/faqs/' },
        { name: 'Reviews', link: '/reviews/' },
      ],
    },
    { name: 'Schools/Districts', link: '/schools-and-districts/' },
    { name: 'Tutorials', link: '/tutorials/' },
    {
      name: 'Learn',
      link: '/learn/',
      subMenu: [
        { name: 'Blog', link: '/blog/' },
        { name: 'Tutorials', link: '/tutorials/' },
        { name: 'Courses', link: '/learn/courses/' },
        // { name: 'E-books', link: '/learn/e-books/' },
      ],
    },
    { name: 'Plans', link: '/pricing/' },
  ];

  const [open, setOpen] = useState(false);
  const pathname = usePathname();

  return (
    <div
      className={`fixed left-0 top-0 z-50 w-full bg-white ${
        displayShadow ? 'shadow-md' : ''
      }`}
    >
      <div className="container mx-auto lg:p-4 xl:px-10">
        <div className="flex items-center gap-6">
          <div className="relative z-10 flex h-full w-full items-center justify-between bg-white px-6 py-5 lg:w-fit lg:p-0">
            <Link href="/">
              <span onClick={() => setOpen(!open)}>
                <Image
                  className="h-7 w-7 cursor-pointer md:h-[40px] md:w-[40px]"
                  src={logoImage}
                  alt="Logo"
                />
              </span>
            </Link>
            <div className="mr-2">
              {path === '/tutorials/' && !open && (
                <span className="mr-2 lg:hidden" aria-label={'Choose Language'}>
                  <ChooseLang isHeader={true} />
                </span>
              )}
              <button
                onClick={() => setOpen(!open)}
                className="lg:hidden"
                aria-label={open ? 'Close menu' : 'Open menu'}
              >
                {open ? (
                  <CgClose size={32} color="#4E4E4E" />
                ) : (
                  <CgMenuRightAlt size={32} color="#4E4E4E" />
                )}
              </button>
            </div>
          </div>
          <div
            className={`${
              open ? 'top-0' : 'top-[-2000px] lg:top-0'
            } fixed left-0 mt-16 h-screen w-full items-center justify-between bg-white px-8 pt-3 duration-500 md:px-32 md:pt-6 lg:relative lg:mt-0 lg:flex lg:h-fit lg:px-0 lg:pt-0`}
          >
            <ul className="flex flex-col gap-1 lg:flex-row lg:flex-wrap lg:justify-center">
              {Links.map((link) => (
                <div key={link.name}>
                  {link.subMenu ? (
                    <DropDownMenu sunMenu={link.subMenu} linkName={link.name} closeMenu={() => setOpen(!open)} />
                  ) : (
                    <Link
                      href={link.link}
                      className="flex px-3 py-2 lg:items-center lg:justify-center"
                      onClick={() => setOpen(!open)}
                    >
                      <span
                        className={`block bg-white ${
                          pathname === link.link
                            ? 'text-[#318AE5]'
                            : 'text-[#404040] duration-150 hover:text-[#318AE5]'
                        }`}
                      >
                        {i18n.t(link.name)}
                      </span>
                    </Link>
                  )}
                </div>
              ))}
            </ul>
            <div className="mt-6 flex items-center gap-4 lg:mt-0 lg:flex-row">
              <Link href={loginUrl}>
                <button className="px-3 py-3.5 font-semibold text-[#318AE5]">
                  <span className="flex items-center">
                    <UserIcon className="mr-1.5 h-6 w-6" />
                    <span className="text-base font-medium">
                      {i18n.t('Log In')}
                    </span>
                  </span>
                </button>
              </Link>
              <Link href={signupUrl}>
                <button className="rounded-lg border-2 border-[#318AE5] bg-[#318AE5] px-5 py-2.5 font-semibold text-white duration-150 hover:opacity-90">
                  {i18n.t('Start for FREE!')}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
