import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import i18n from '@/src/i18n';

export function DropDownMenu({
  linkName,
  sunMenu,
  closeMenu,
}: {
  linkName: string;
  sunMenu: any;
  closeMenu?: () => void;
}) {
  const path = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
  };
  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => setIsOpen(false), 100);
  };

  if (isTabletOrMobile) {
    return (
      <div>
        <Disclosure>
          {({ open }) => (
            <>
              <DisclosureButton
                className={
                  'hover:no-scrollbar flex  w-full items-center px-3 py-2.5 text-left'
                }
              >
                <span className="text-[16px] font-normal text-[#404040]">
                  {linkName}
                </span>
                <ChevronDownIcon
                  className={`ml-1 mt-1 size-4 ${open ? 'rotate-180' : ''}`}
                />
              </DisclosureButton>
              <DisclosurePanel className="no-scrollbar overflow-y-auto px-4 pb-4 pt-2 text-sm text-gray-500">
                {sunMenu.map((item: any, idx: number) => (
                  <Link
                    href={item.link}
                    key={idx}
                    onClick={() => {
                      window.location.href = item.link;
                      closeMenu && closeMenu();
                    }}
                  >
                    <span
                      className={`${
                        idx !== 0 && 'mt-1'
                      } block rounded-md bg-white px-3 py-[6px] text-base font-normal hover:cursor-pointer hover:bg-[#F6F7F8] ${
                        path === item.link ? 'text-[#318AE5]' : 'text-[#464E5F]'
                      }`}
                    >
                      {i18n.t(item.name)}
                    </span>
                  </Link>
                ))}
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </div>
    );
  }

  return (
    <Menu
      as="div"
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MenuButton className="flex items-center justify-center space-x-2 rounded-md px-3 py-2 data-[hover]:bg-[#F6F7F8] data-[open]:bg-[#F6F7F8]">
        <span className="text-[16px] font-normal text-[#404040]">
          {linkName}
        </span>
        <ChevronDownIcon className="size-4" />
      </MenuButton>

      {isOpen && (
        <MenuItems
          static
          className="absolute z-[100000] mt-2 w-[189px] rounded-lg border border-[#ECEEF0] bg-white p-2 shadow-[0px_0px_12px_0px_rgba(0,0,0,0.06)]"
        >
          {sunMenu.map((item: any, idx: number) => (
            <MenuItem key={idx}>
              <Link href={item.link} onClick={() => setIsOpen(false)}>
                <span
                  className={`${
                    idx !== 0 && 'mt-1'
                  } block rounded-md bg-white px-3 py-[6px] text-base font-normal hover:cursor-pointer hover:bg-[#F6F7F8] ${
                    path === item.link ? 'text-[#318AE5]' : 'text-[#464E5F]'
                  }`}
                >
                  {i18n.t(item.name)}
                </span>
              </Link>
            </MenuItem>
          ))}
        </MenuItems>
      )}
    </Menu>
  );
}
